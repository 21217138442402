import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Hakkımızda | Ayse Saday School
			</title>
			<meta name={"description"} content={"Uzman Dil Eğitimi ile Potansiyelinizi Açığa Çıkarın"} />
			<meta property={"og:title"} content={"Hakkımızda | Ayse Saday School"} />
			<meta property={"og:description"} content={"Uzman Dil Eğitimi ile Potansiyelinizi Açığa Çıkarın"} />
			<meta property={"og:image"} content={"https://echoversevip.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://echoversevip.com/images/46-325235.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://echoversevip.com/images/46-325235.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://echoversevip.com/images/46-325235.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://echoversevip.com/images/46-325235.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://echoversevip.com/images/46-325235.png"} />
			<meta name={"msapplication-TileImage"} content={"https://echoversevip.com/images/46-325235.png"} />
			<meta name={"msapplication-TileColor"} content={"https://echoversevip.com/images/46-325235.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="Reviews-3">
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 50px/1.5 --fontFamily-sansHelvetica"
				color="--primary"
				lg-margin="0px 0px 20px 0px"
				text-align="center"
			>
				Kapsamlı Dil Öğrenme Çözümleri
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 400 22px/1.2 --fontFamily-sans" text-align="center" md-margin="0px 0px 50px 0px">
				Ayse Saday School'de, öğrencilerimizin benzersiz ihtiyaçlarını karşılamak için tasarlanmış çok çeşitli hizmetler sunuyoruz. İster konuşma becerilerinizi geliştirmek, ister iş İngilizcesinde ustalaşmak veya önemli bir sınava hazırlanmak istiyor olun, sizin için doğru kursa sahibiz. Amacımız, İngilizce dil yolculuğunuzda mükemmelleşmek için ihtiyaç duyduğunuz araçları ve güveni size sağlamaktır.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 30px"
				justify-content="center"
				lg-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
				sm-grid-gap="30px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
						Profesyonel İletişim: Toplantılarda, sunumlarda ve müzakerelerde başarılı olmak için iş dilini öğrenin.
						<br/><br/>
						Yazma Becerileri: Açık ve etkili iş belgeleri, e-postalar ve raporlar yazma becerinizi geliştirin.
						<br/><br/>
						Kültürel Farkındalık: Kültürel farklılıkları anlamak ve uluslararası iş ortamları için beceriler geliştirmek.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
						IELTS/TOEFL Eğitimi: Kapsamlı hazırlık kursları ile İngilizce yeterlilik sınavlarınıza hazır olun.
						<br/><br/>
Deneme Sınavları: Testlerin formatına ve zamanlamasına aşina olmak için deneme sınavlarına girin.
						<br/><br/>
Başarı için Stratejiler: Puanlarınızı en üst düzeye çıkarmak için sınava girme stratejilerini ve tekniklerini öğrenin.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
						Akademik İngilizce: Yüksek öğrenimde başarı için akademik yazma ve araştırma becerilerinizi geliştirin.
						<br/><br/>
Seyahat İngilizcesi: Pratik kelime ve deyimlere odaklanan kurslarla seyahatlerinize hazırlanın.
						<br/><br/>
Özelleştirilmiş Programlar: Belirli dil öğrenme ihtiyaçlarını ve hedeflerini karşılamak için özel olarak hazırlanmış kurslar.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					padding="30px 30px 30px 30px"
					background="#f3f3f7"
					border-radius="8px"
					box-shadow="--m"
					margin="0px 0 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					lg-margin="0px 0 0 0px"
					sm-padding="20px 15px 20px 15px"
					md-padding="20px 20px 20px 20px"
				>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 28px 0px" font="normal 300 17px/1.5 --fontFamily-sansHelvetica" color="--darkL1" md-margin="0px 0px 18px 0px">
						Sanal Sınıflar: Evinizin rahatlığında canlı, interaktif derslere katılın.
						<br/><br/>
Esnek Programlama: Yoğun yaşam tarzınıza uygun ders saatlerini seçin.
						<br/><br/>
Dijital Kaynaklar: Öğreniminizi desteklemek için çok sayıda çevrimiçi materyal ve kaynağa erişin.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>

		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-primary">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
				Daha Fazla Bilgi İçin Bize Ulaşın
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
				Ve bu sadece bir başlangıç. Bugün bizimle iletişime geçerek tekliflerimizin tamamını keşfedin. Kurslarımız, programlarımız veya kayıt sürecimiz hakkında daha fazla bilgi edinmek istiyorsanız, güler yüzlü personelimiz size yardımcı olmak için burada.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://echoversevip.com/images/4.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://echoversevip.com/images/5.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Ayse Saday School ile İngilizce dilinde ustalaşmak için ilk adımı atın. Kapsamlı hizmetlerimiz ve uzman eğitmenlerimiz size her adımda rehberlik etmek için burada.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});